const getConceptsMap = (domains) => {
  return domains.reduce(
    (acc, domain) => {
      const maps = domain.concepts.reduce(
        (conceptAcc, concept) => {
          return {
            ...conceptAcc,
            conceptsMap: {
              ...conceptAcc.conceptsMap,
              [concept.uri]: concept,
            },
            superClassMap: {
              ...conceptAcc.superClassMap,
              ...concept.inheritedConcepts.reduce(
                (i, inheritedConcept) => ({ ...i, [inheritedConcept]: true }),
                {}
              ),
            },
          };
        },
        { conceptsMap: acc.conceptsMap, superClassMap: acc.superClassMap }
      );

      return {
        ...acc,
        conceptsMap: maps.conceptsMap,
        superClassMap: maps.superClassMap,
      };
    },
    { conceptsMap: {}, superClassMap: {} }
  );
};

const getInheritedProps = (
  inheritedConcepts,
  conceptsMap,
  base = { properties: [], relations: [] }
) => {
  return inheritedConcepts.reduce((acc, concept) => {
    const newVal = {
      ...acc,
      properties: [...acc.properties, ...conceptsMap[concept].properties],
      relations: [
        ...acc.relations,
        ...conceptsMap[concept].relations.map((rel) => ({ ...rel, isRelation: true })),
      ],
    };

    if (conceptsMap[concept].inheritedConcepts.length === 0) {
      // base condition, return label, props & relations
      return newVal;
    } else {
      // recursively pick out inherited props
      return getInheritedProps(conceptsMap[concept].inheritedConcepts, conceptsMap, newVal);
    }
  }, base);
};

export const getNodesMapAndSuperclasses = (domains) => {
  const maps = getConceptsMap(domains);
  const conceptsMap = maps.conceptsMap;

  const nodesMap = domains.reduce((acc, domain) => {
    return {
      ...acc,
      ...domain.concepts.reduce((accConcept, concept) => {
        const inherited = getInheritedProps(concept.inheritedConcepts, conceptsMap, {
          properties: [],
          relations: [],
        });
        const all = {
          properties: [
            ...concept.properties,
            ...inherited.properties,
            ...concept.relations.map((rel) => ({ ...rel, isRelation: true })),
            ...inherited.relations,
          ],
          relations: [...concept.relations, ...inherited.relations],
        };
        return {
          ...accConcept,
          [concept.uri]: {
            ...concept,
            domainColor: domain.color,
            domainName: domain.name,
            domainUri: domain.uri,
            properties: all.properties,
            relations: all.relations.reduce(
              (relAcc, rel) => ({
                ...relAcc,
                [rel.label]: rel,
              }),
              {}
            ),
          },
        };
      }, {}),
    };
  }, {});

  return { nodesMap, superClassMap: maps.superClassMap };
};
