import { useRouteError } from 'react-router-dom-v5-compat';

import ErrorMessage from './ErrorMessage';
import Refresh from './Refresh';

export default function ErrorPage() {
  const error = useRouteError() as { statusText: number | null; message: string | null };

  return (
    <ErrorMessage
      errorMessageProps={{ style: { width: 500 } }}
      containerProps={{ className: 'w-100' }}
    >
      <>
        <Refresh />
        <div className="ma-24-top">
          <i>{error.statusText || error.message}</i>
        </div>
      </>
    </ErrorMessage>
  );
}
