import { ISubQuestionsState } from './subQuestions.interface';

export const subQuestionInitialState: ISubQuestionsState = {
  selectedSubQuestion: {
    id: null,
    uri: '',
    color: '',
    outputs: [],
  },
};
