import React, { ReactNode } from 'react';
import CloseAndHelpBtns from './CloseAndHelpBtns';
import './StudioModal.css';

const sizingConstant = 95;
const height = `${sizingConstant}vh`;
const width = `${sizingConstant}vw`;

export type StudioModalProps = {
  children?: ReactNode;
  className?: string;
  customStyle?: any;
  modalName?: string;
  showHelp?: boolean;
  toggleShowHelp?: any;
  closeModal?: any;
  showBackBtn?: boolean;
  handleGoBack?: any;
  hideHelpBtn?: boolean;
  modalHeight?: string;
  modalWidth?: string;
  closeIconTestId?: string;
  sizing?: string;
  showWarning?: boolean;
  showError?: boolean;
  modalContainerProps?: any;
  hideCloseButton?: boolean;
};

export function StudioModal({
  children,
  className = '',
  customStyle = {},
  modalName,
  showHelp,
  toggleShowHelp,
  closeModal,
  showBackBtn = false,
  handleGoBack,
  hideHelpBtn = false,
  modalHeight = height,
  modalWidth = width,
  closeIconTestId = '',
  sizing = 'large',
  showWarning = false,
  showError = false, // If both Show Warning and Show Error are true, then precendce is given to Error
  modalContainerProps = {},
  hideCloseButton = false,
}: StudioModalProps) {
  return (
    <div
      className="modal-background studio-modal w-100 vh-100 flex justify-center items-center"
      style={{
        zIndex: 1500,
        ...customStyle,
      }}
    >
      <div
        className={'bg-white bn br1 picklewood-blue 0-100 flex flex-column' + className}
        style={{
          height: modalHeight,
          // TODO: define sizing min & max widths w/designer
          width: sizing === 'small' ? 'var(--sizing720)' : modalWidth,
        }}
        {...modalContainerProps}
      >
        <CloseAndHelpBtns
          {...{
            modalName,
            showHelp,
            toggleShowHelp,
            closeModal,
            showBackBtn,
            handleGoBack,
            hideHelpBtn,
            closeIconTestId,
            showWarning,
            showError,
            hideCloseButton,
          }}
        />
        {children}
      </div>
    </div>
  );
}

export default StudioModal;
