import React, { ReactElement, useContext } from 'react';

import '../ButtonGeneralStyles.css';
import './Button.css';
import { context } from '../../../Theme/Context';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  testId: string;
  label: string | ReactElement;
  variant:
    | 'secondary'
    | 'primary'
    | 'transparent'
    | 'obscure'
    | 'shade'
    | 'tints-default-medium-light';
}

const Button: React.FC<Props> = ({
  testId,
  type = 'button',
  variant,
  label,
  disabled,
  className,
  ...rest
}): ReactElement => {
  const theme = useContext(context);

  return (
    <button
      type={type}
      data-testid={testId}
      className={
        `kobai-button button--${variant} h-40 pa-24-horizontal flex items-center pointer fs-14 lh-16 fw4 bn ` +
        (disabled ? 'o-50 ' : '') +
        className
      }
      disabled={disabled}
      style={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        backgroundColor:
          variant === 'primary' && theme?.useCustomTheme ? theme.colors.background : undefined,
        color: variant === 'primary' && theme?.useCustomTheme ? theme.colors.foreground : undefined,
      }}
      {...rest}
    >
      {label}
    </button>
  );
};

export default Button;
