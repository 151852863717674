import { size } from 'underscore';

const window_env = (window as any)._env_;

const envVariables: Record<string, any> =
  window_env && size(window_env) > 0
    ? Object.keys(window_env).reduce((acc, variable) => {
        // skip non REACT_APP variables
        if (!variable.includes('REACT_APP')) {
          return acc;
        }
        let value = window_env[variable];
        variable = variable.replace('REACT_APP_', '');
        const varName = variable.toLowerCase().replace(/_([a-z])/g, (g) => g[1].toUpperCase());
        return {
          ...acc,
          [varName]: value,
        };
      }, {})
    : {};

export default envVariables;
