import { getColorSchemes } from '../../../../utils/ColorSchemes';
import { labelColor } from './GraphUtils';
import {
  UNSELECTED_CONCEPT_COLOR,
  GRAPH_NODE_TOOLTIP_DEFAULTS,
  graphNodeTooltipHTML,
  UNSELECTED_CONCEPT_SYMBOL_SIZE,
  SELECTED_CONCEPT_SYMBOL_SIZE,
} from '../constants/graphNode.constant';
import { subQuestionSymbol } from '../constants/subQuestionSymbol.constant';
import { GRAPH_NODE_EDGE_DEFAULTS } from '../constants/graphNodeEdge.constants';
import { SELF_JOIN_RELATION_NAME } from '../constants/queryCreation.constants';

const color = getColorSchemes('lineChart');

export const getDomainName = (conceptUri) => {
  try {
    let categories = conceptUri.split('/');
    return categories[categories.length - 1].split('#')[0];
  } catch (e) {
    return '';
  }
};

/**
 * Returns the defaults settings for the graph node
 * @param {string} conceptURI
 * @param {object} concept
 * @param {string} graphType
 * @param {string} domainColor
 * @param {string} domainName
 * @returns ECharts graph node
 */
export const getNode = (conceptURI, concept, graphType, domainColor, domainName, isSubQuestion) => {
  var graphNode = {
    isSubQuestion,
    itemStyle: {
      color: concept.color,
      originalColor: concept.color,
    },
    value: 14,
    symbolSize: UNSELECTED_CONCEPT_SYMBOL_SIZE,
    originalSymbolSize: UNSELECTED_CONCEPT_SYMBOL_SIZE,
    attributes: {
      modularity_class: domainName,
    },
    category: domainName,
    label: {
      show: true,
      color: labelColor,
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 300,
      width: 150,
      overflow: 'break',
    },
    symbol: isSubQuestion ? subQuestionSymbol : 'circle',
    emphasis: {
      symbolSize: 45,
    },
    name: concept.label,
    id: conceptURI,
    graphType,
    tooltip: {
      ...GRAPH_NODE_TOOLTIP_DEFAULTS,
      formatter: (params) => {
        if (params.dataType === 'node') {
          const propCount = concept.properties.length;
          const relCount = Object.keys(concept.relations).reduce(
            (acc, curr) => (acc += concept.relations[curr].relationInstances.length),
            0
          );
          return graphNodeTooltipHTML(propCount, relCount, domainColor, domainName, isSubQuestion);
        } else {
          return '';
        }
      },
    },
  };
  return graphNode;
};

export const getEdges = (conceptId, concept, isSubQuestion) => {
  let graphEdges = [];

  if (isSubQuestion) {
    // get subQuestion graph edges to the original question
    Object.keys(concept.subQueryDefinition).forEach((subQuestionConcept) => {
      Object.keys(concept.subQueryDefinition[subQuestionConcept].relations).forEach(
        (subQuestionRelationName) => {
          concept.subQueryDefinition[subQuestionConcept].relations[
            subQuestionRelationName
          ].relationInstances.forEach((relationInstance) => {
            const isSelfJoin = subQuestionRelationName === SELF_JOIN_RELATION_NAME;

            graphEdges.push({
              source: conceptId,
              target: relationInstance.relationTypeUri,
              name: subQuestionRelationName,
              label: {
                ...GRAPH_NODE_EDGE_DEFAULTS.label,
                formatter: isSelfJoin ? '' : relationInstance,
              },
              lineStyle: {
                ...GRAPH_NODE_EDGE_DEFAULTS.lineStyle,
                color: isSelfJoin ? '#182027' : concept.color, //'#979797',
                type: isSelfJoin ? 'dashed' : 'solid',
                dashOffset: 5,
              },
              emphasis: GRAPH_NODE_EDGE_DEFAULTS.emphasis,
            });
          });
        }
      );
    });
  }

  if ('relations' in concept) {
    Object.keys(concept.relations).forEach((relationName) => {
      concept.relations[relationName].relationInstances.forEach((relationInstance) => {
        const isSelfJoin = relationName === SELF_JOIN_RELATION_NAME;
        graphEdges.push({
          source: conceptId,
          // if the relation is going to the subQuery, point to the subQueryUri instead of the relationTypeUri
          target: relationInstance.subQueryUri
            ? relationInstance.subQueryUri
            : relationInstance.relationTypeUri,
          name: relationName,
          label: {
            ...GRAPH_NODE_EDGE_DEFAULTS.label,
            formatter: isSelfJoin ? '' : relationName,
          },
          lineStyle: {
            ...GRAPH_NODE_EDGE_DEFAULTS.lineStyle,
            color: isSelfJoin ? '#182027' : concept.color, //'#979797',
            type: isSelfJoin ? 'dashed' : 'solid',
            dashOffset: 5,
          },
          emphasis: GRAPH_NODE_EDGE_DEFAULTS.emphasis,
        });
      });
    });
  }

  if ('superClasses' in concept) {
    concept.superClasses.forEach(function (key) {
      graphEdges.push({
        source: concept.uri,
        target: key,
        name: key,
        label: { show: true, formatter: '' },
        lineStyle: { type: 'dashed', curveness: 0.2 },
      });
    });
  }
  return graphEdges;
};

export const getConceptProps = (concept) => {
  var conceptProps = [];
  if ('relations' in concept) {
    var conceptProp;
    Object.keys(concept.properties).forEach(function (key) {
      conceptProp = {
        propName: concept.properties[key].label,
        propType: concept.properties[key].propTypeLabel,
      };
      conceptProps.push(conceptProp);
    });
  }
  return conceptProps;
};

/**
 * Iterates the question queryMap JSON & nodesMap to return the graph nodes & edges
 * @param {*} queryMap
 * @param {*} nodesMap
 * @param {*} subQueries
 * @returns
 */
export const getGraphData = (queryMap, nodesMap, subQueries = {}) => {
  let categoryMap = {};

  const graphData = Object.keys(queryMap)
    .sort()
    .reduce(
      (acc, conceptURI) => {
        const isSubQuestion =
          queryMap[conceptURI]?.subQueryId && queryMap[conceptURI]?.subQueryId !== null;

        const domainName = isSubQuestion
          ? subQueries?.[queryMap[conceptURI]?.subQueryId]?.description
          : getDomainName(conceptURI);

        const concept = isSubQuestion
          ? {
              ...queryMap[conceptURI],
              label: domainName,
            }
          : queryMap[conceptURI];

        let categories = [...acc.categories];

        if (!(domainName in categoryMap)) {
          categoryMap[domainName] = true;
          categories.push({ name: domainName });
        }

        const domainColor = isSubQuestion ? concept?.color : nodesMap[concept?.uri]?.domainColor;

        return {
          graphNodes: [
            ...acc.graphNodes,
            getNode(conceptURI, concept, 'query', domainColor, domainName, isSubQuestion),
          ],
          graphEdges: [...acc.graphEdges, ...getEdges(conceptURI, concept, isSubQuestion)],
          categories,
          conceptProps: {
            ...acc.conceptProps,
            [conceptURI]: getConceptProps(concept),
          },
        };
      },
      { graphNodes: [], graphEdges: [], categories: [], conceptProps: {} }
    );

  return graphData;
};

/**
 * Uses the queryMap & nodesMap to add the series to the question graph
 * @param {IQuestionDefinition} queryMap
 * @param {*} nodesMap
 * @param {*} seriesSettings
 * @returns ECharts graph options, w/series
 */
export const getQueryGraphOptions = (
  queryMap,
  nodesMap,
  seriesSettings = { zoom: 1 },
  subQueries = {}
) => {
  let edges = null;
  const graphData = getGraphData(queryMap, nodesMap, subQueries);

  return {
    color,
    tooltip: {
      trigger: 'item',
    },
    series: [
      {
        name: 'Model',
        type: 'graph',
        layout: 'circular',
        circular: {
          rotateLabel: true,
        },
        data: graphData.graphNodes,
        links: edges || graphData.graphEdges,
        categories: graphData.categories,
        roam: true,
        emphasis: {
          focus: 'adjacency',
          scale: true,
        },
        hoverAnimation: true,
        nodeScaleRation: 1.5,
        edgeSymbol: ['none', 'arrow'],
        animationDuration: 1500,
        animationEasingUpdate: 'quinticInOut',
        top: '20%',
        bottom: '20%',
        left: '20%',
        right: '20%',
        ...seriesSettings,
      },
    ],
  };
};

/**
 * This helper function is used by DomainGraph.jsx
 * @param {number} seriesIndex
 * @param {number} dataIndex
 * @param {ECharts graph series} series
 * @returns
 */
export const updateChartOption = (seriesIndex, dataIndex, series) => {
  if (seriesIndex === -1) {
    return series;
  } else {
    return [
      ...series.slice(0, seriesIndex),
      {
        ...series[seriesIndex],
        data: series[seriesIndex].data.reduce((acc, curr, index) => {
          // update the itemStyle to gallery for concepts that are NOT the selected concept
          // update symbol size to 40 for the selected concept
          const newColor = curr.itemStyle.originalColor;

          return [
            ...acc,
            {
              ...curr,
              itemStyle: {
                color: index === dataIndex ? newColor : UNSELECTED_CONCEPT_COLOR,
              },
              symbolSize:
                index === dataIndex ? SELECTED_CONCEPT_SYMBOL_SIZE : UNSELECTED_CONCEPT_SYMBOL_SIZE,
              emphasis: {
                itemStyle: curr.emphasis.itemStyle
                  ? {
                      ...curr.emphasis.itemStyle,
                      color: newColor,
                    }
                  : {
                      color: newColor,
                    },
              },
            },
          ];
        }, []),
      },
      ...series.slice(seriesIndex + 1),
    ];
  }
};
