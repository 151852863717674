import { size } from 'underscore';

const Features =
  window._env_ && size(window._env_) > 0
    ? Object.keys(window._env_).reduce((acc, feature) => {
        if (feature.includes('REACT_APP')) {
          // skip REACT_APP features
          return acc;
        }
        const featureName = feature.toLowerCase().replace(/_([a-z])/g, (g) => g[1].toUpperCase());

        let value = window._env_[feature];
        switch (value) {
          case 'true':
            value = true;
            break;

          case 'false':
            value = false;
            break;

          default:
            break;
        }

        return {
          ...acc,
          [featureName]: value,
        };
      }, {})
    : {};

export default Features;
