import {
  IConcept,
  INodesMap,
  IQuestionById,
  IQuestionDefinition,
} from '@redux/Studio/QueryCreation/constants/queryCreation.interface';
import { IOutputItem } from '../constants/subQuestions.interface';
import { getProp, getRelation } from './getOutputs';
import { PROPERTY_PROPS, RELATION_PROPS } from '../constants/outputItems';
import {
  SELF_JOIN_LABEL,
  SELF_JOIN_RELATION_NAME,
} from '@redux/Studio/QueryCreation/constants/queryCreation.constants';

type existingPropsMapType = {
  [key: string]: boolean;
};

type accType = {
  props: IOutputItem[];
  existingPropsMap: existingPropsMapType;
};

const getProperties = (
  concept: IConcept,
  conceptId: string,
  isExisting: boolean,
  subQuestionUri: string
) => {
  return concept.properties.reduce(
    (acc: accType, prop) => {
      return {
        props: [
          ...acc.props,
          getProp(prop, concept, conceptId, isExisting, subQuestionUri, concept.color || ''),
        ],
        existingPropsMap: {
          ...acc.existingPropsMap,
          [prop.uri]: true,
        },
      };
    },
    { props: [], existingPropsMap: {} }
  );
};

const getAvailableRelations = (
  concept: IConcept,
  conceptId: string,
  isExisting: boolean,
  nodesMap: INodesMap,
  availableRelationsMap: { [key: string]: string },
  subQuestionUri: string
) => {
  return Object.keys(nodesMap?.[concept.uri]?.relations || {}).reduce(
    (relAcc: { props: IOutputItem[]; existingPropsMap: { [key: string]: string } }, relName) => {
      const rel = nodesMap[concept.uri].relations[relName];

      // prevent adding duplicate relations.
      // This use case happens when there are multiple concept instances of the same concept
      // with a concept connecting to them via the same relation name
      // example:
      // conceptA1 -> conceptB1
      // conceptA1 -> conceptB2 via the same relation type
      if (
        !relAcc.existingPropsMap[rel.relationTypeUri] ||
        relAcc.existingPropsMap[rel.relationTypeUri] !== rel.uri
      ) {
        return {
          props: [
            ...relAcc.props,
            getRelation(rel, concept, conceptId, isExisting, subQuestionUri, concept.color || ''),
          ],
          existingPropsMap: {
            ...relAcc.existingPropsMap,
            [rel.relationTypeUri]: rel.uri,
          },
        };
      } else {
        return relAcc;
      }
    },
    { props: [], existingPropsMap: availableRelationsMap }
  );
};

const getRelationsToOriginalQuestion = (
  concept: IConcept,
  conceptId: string,
  isExisting: boolean,
  subQuestionUri: string
) => {
  return Object.keys(concept.relations).reduce(
    (acc: { props: IOutputItem[]; existingPropsMap: existingPropsMapType }, relName) => {
      const isSelfJoin = relName === SELF_JOIN_RELATION_NAME;
      const rel = {
        ...concept.relations[relName],
        selfJoin: isSelfJoin,
      };

      if (isSelfJoin) {
        return rel.relationInstances.reduce(
          (instanceAcc: { props: IOutputItem[]; existingPropsMap: existingPropsMapType }, item) => {
            return {
              props: [
                ...instanceAcc.props,
                getRelation(
                  {
                    ...rel,
                    relationTypeUri: item.relationTypeUri,
                    uri: conceptId,
                  },
                  concept,
                  conceptId,
                  isExisting,
                  subQuestionUri,
                  concept.color || ''
                ),
              ],
              existingPropsMap: {
                ...instanceAcc.existingPropsMap,
              },
            };
          },
          acc
        );
      }

      return {
        props: [
          ...acc.props,
          getRelation(rel, concept, conceptId, isExisting, subQuestionUri, concept.color || ''),
        ],
        existingPropsMap: {
          ...acc.existingPropsMap,
          [rel.relationTypeUri]: true,
        },
      };
    },
    { props: [], existingPropsMap: {} }
  );
};

/**
 * Iterates the definition, reference sub-question for the available outputs (properties & aggregates)
 * & nodesMap to get all of the available relations for a concept
 * @param subQuestionDefinition
 * @param referenceDefinition
 * @returns
 */
export const getSelectedSubQueryOutputsAndRelations = (
  subQuestionDefinition: IQuestionDefinition,
  referenceDefinition: IQuestionDefinition,
  nodesMap: INodesMap,
  subQuestionUri: string,
  query: IQuestionById
) => {
  // These are the props that have been added to the sub-q
  const existingProps = Object.keys(subQuestionDefinition).reduce(
    (acc: accType, conceptURI) => {
      const concept = subQuestionDefinition[conceptURI];

      const props = getProperties(concept, conceptURI, true, subQuestionUri);
      const relations = getRelationsToOriginalQuestion(concept, conceptURI, true, subQuestionUri);

      // Self joins to the original question
      const selfJoins = Object.keys(query.definition).reduce(
        (acc: IOutputItem[], conceptInstance) => {
          // check to see if the instance is already there as a selfJoin in the relations
          const indexOfExisting = relations.props.findIndex(
            (item: IOutputItem) =>
              item.relationTypeUri === conceptInstance && item.label === SELF_JOIN_LABEL
          );

          if (concept.uri === query.definition[conceptInstance].uri && indexOfExisting === -1) {
            return [
              ...acc,
              {
                ...PROPERTY_PROPS,
                ...RELATION_PROPS,
                label: subQuestionDefinition[conceptURI].label,
                relationTypeUri: conceptInstance, //conceptURI,
                uri: subQuestionUri,
                isExisting: false,
                selfJoin: true,
                isRelation: true,
                conceptId: conceptURI, //the concept in the sub-question
                conceptName:
                  subQuestionDefinition[conceptURI].outputCategory ||
                  subQuestionDefinition[conceptURI].label,
                subQuestionUri: subQuestionUri,
                color: query.definition[conceptInstance].color || '',
              },
            ];
          }
          return acc;
        },
        []
      );

      return {
        props: [...acc.props, ...props.props, ...relations.props, ...selfJoins],
        existingPropsMap: {
          ...acc.existingPropsMap,
          ...props.existingPropsMap,
          ...relations.existingPropsMap,
        },
      };
    },
    { props: [], existingPropsMap: {} }
  );

  let availableRelationsMap = {};

  // These are the props that are available from the original question AND
  // All relations possible
  const allProps = Object.keys(referenceDefinition).reduce((acc: IOutputItem[], curr) => {
    const concept = referenceDefinition[curr];

    const filteredProps = concept.properties.reduce((propAcc: IOutputItem[], prop) => {
      // prevent adding props that are already added OR are hidden
      if (prop.uri in existingProps.existingPropsMap || prop.hidden) {
        return propAcc;
      } else {
        return [
          ...propAcc,
          getProp(prop, concept, curr, false, subQuestionUri, concept.color || ''),
        ];
      }
    }, []);

    // Get all relations possible, prevent dupes w/availableRelationsMap
    const availableRelations = getAvailableRelations(
      concept,
      curr,
      false,
      nodesMap,
      availableRelationsMap,
      subQuestionUri
    );

    availableRelationsMap = availableRelations.existingPropsMap;

    return [...acc, ...filteredProps, ...availableRelations.props];
  }, existingProps.props);

  return allProps.sort((a, b) => a.label.toLocaleLowerCase().localeCompare(b.label.toLowerCase()));
};
